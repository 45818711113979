import { useEffect } from "react";
import { ArrowLeft } from "@/assets";
import { QuerySuspense } from "@/components";
import { Layout } from "@/components/layout";
import { TransferAccountProvider } from "@/features/transfers/providers";
import { useMediaQuery, useNavigate } from "@/hooks";
import { useGetBanks } from "@/services/banks";
import { routes, setHeader } from "@/utils";
import { Breadcrumb } from "@hyperlocal/vital2";
import { Link, Outlet, useLocation } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";

const returnNavigationMap = {
  [routes.transfers]: routes.dashboard,
  [routes.transfersData]: routes.transfers,
  [routes.transfersConfirmTransfer]: routes.transfers,
  [routes.transfersEditContact]: routes.transfersConfirmTransfer,
};

export const TransferRoot = () => {
  const isMobile = useMediaQuery("mobile");

  const navigate = useNavigate();
  const location = useLocation();

  useGetBanks();

  useEffect(() => {
    if (isMobile) {
      setHeader({
        title: "Transferência",
        leftIcon: {
          name: "ArrowNoLineLeft",
          onClick: () => navigate(returnNavigationMap[location.pathname]),
        },
      });
    } else {
      setHeader({
        title: "",
      });
    }
  }, [isMobile, location.pathname, navigate]);

  return (
    <div className="flex h-full w-full flex-1 flex-col bg-neutral-lighter mobile:!max-h-[100svh] mobile:!bg-neutral-white">
      <Layout.Root>
        <Layout.Header>
          <Breadcrumb.Root className="mobile:!hidden">
            <Breadcrumb.List>
              <Breadcrumb.ListItem>
                <Link to={routes.dashboard}>
                  <Breadcrumb.Home className="!ml-0" />
                </Link>
              </Breadcrumb.ListItem>
              <Breadcrumb.ListItem>
                <Breadcrumb.Separator />
                <Breadcrumb.Anchor active>Transferência</Breadcrumb.Anchor>
              </Breadcrumb.ListItem>
            </Breadcrumb.List>
          </Breadcrumb.Root>
        </Layout.Header>
        <Layout.Title className="mobile:!hidden">
          <Link to={routes.dashboard}>
            <ArrowLeft />
          </Link>
          Transferências
        </Layout.Title>
        <Layout.Content>
          <Layout.Column className="flex h-[700px] flex-col gap-inset-lg mobile:!h-[calc(100vh-56px)]">
            <Parcel
              config={() => System.import("@hyperlocal/banking-balance")}
              mountParcel={mountRootParcel}
            />
            <div className="h-full overflow-hidden rounded-sm bg-neutral-white">
              <QuerySuspense>
                <TransferAccountProvider>
                  <Outlet />
                </TransferAccountProvider>
              </QuerySuspense>
            </div>
          </Layout.Column>
          <Layout.Column className="absolute inset-0 col-[2] overflow-hidden bg-neutral-white mobile:!inset-auto mobile:!col-auto mobile:!hidden ">
            <Parcel
              config={() => System.import("@hyperlocal/banking-statements")}
              mountParcel={mountRootParcel}
              type={["P2P", "TED", "Pix"]}
              wrapStyle={{
                height: "100%",
                overflow: "hidden",
              }}
            />
          </Layout.Column>
        </Layout.Content>
      </Layout.Root>
    </div>
  );
};
