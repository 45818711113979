import { ScrollArea } from "@/components";
import { useTransferAccount } from "@/features/transfers/providers";
import { useHandleSearchDependencies, useNavigate } from "@/hooks";
import { queryClient } from "@/lib";
import { useSuspenseGetContacts, useUpdateAccount } from "@/services/contacts";
import { Contact, ContactAccount, TransferType } from "@/types";
import {
  canEditContacts,
  CPF_DIGITS,
  DOCK_BANK_CODE,
  formatAccount,
  formatDocument,
  getAccountNumber,
  getVerticalBankName,
  handleInputErrorStatus,
  hasPermission,
  MASKED_INPUT_DEFAULT_PROPS,
  queries,
  routes,
  URL_PARAMS,
  useAccountStore,
} from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, RadioGroup, Switch, TextField } from "@hyperlocal/vital2";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useHookFormMask } from "use-mask-input";
import { SelectBankComboBox, TransferLayout } from "../../components";
import {
  DEFAULT_VALUES,
  TransferDataValidationSchema,
  transferDataValidationSchema,
} from "./schema";

const { contactKeys } = queries;

type ContactExistsResponse =
  | {
      type: "NEW_CONTACT";
    }
  | {
      type: "NEW_ACCOUNT";
      contact: Contact;
    }
  | {
      type: "ACCOUNT_EXISTS";
      account: ContactAccount;
      contact: Contact;
    };

const findSameAccount = ({
  contact,
  accountInfo,
}: {
  contact: Contact;
  accountInfo: TransferDataValidationSchema;
}) => {
  const account = contact.ContactAccounts.find((account) => {
    if (account.TransferType === "pix") return null;

    const { accountDigit, accountNumber } = getAccountNumber(
      accountInfo.account,
    );

    const isSameBranch = account.Branch === accountInfo.branch;

    const isSameAccountNumber = account.AccountNumber === accountNumber;

    const isSameAccountDigit = account.AccountDigit === accountDigit;

    const bankCode =
      (account.TransferType === "p2p"
        ? DOCK_BANK_CODE.toString()
        : account.BankCode) === accountInfo.bank.bankCode;

    if (isSameBranch && isSameAccountNumber && isSameAccountDigit && bankCode)
      return account;

    return null;
  });

  return account;
};

const contactExists = ({
  contactList,
  newContact,
}: {
  newContact: TransferDataValidationSchema;
  contactList: Contact[];
}): ContactExistsResponse => {
  const contact = contactList.find(
    (contact) => contact.Document === newContact.document,
  );

  if (!contact) return { type: "NEW_CONTACT" };

  const account = findSameAccount({ accountInfo: newContact, contact });

  if (account) return { type: "ACCOUNT_EXISTS", account, contact };

  return { type: "NEW_ACCOUNT", contact };
};

export const TransferData = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const transferType = searchParams.get(URL_PARAMS.type) as TransferType;

  const document = searchParams.get(URL_PARAMS.document) || "";

  const isP2PTransfer = transferType === "p2p";

  const isTEDTransfer = transferType === "ted";

  const dockBankData = {
    bankCode: DOCK_BANK_CODE.toString(),
    bankName: getVerticalBankName(),
    ispb: "13370835",
    shortBankName: getVerticalBankName(),
  };

  const { currentAccountId: accountId } = useAccountStore();

  const { data } = useSuspenseGetContacts();

  const { updateTransferAccount } = useTransferAccount();

  const updateContact = useUpdateAccount({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: contactKeys.getContactList({
          accountId,
        }),
      });
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    register,
    watch,
    setError,
  } = useForm<TransferDataValidationSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      document,
    },
    resolver: zodResolver(transferDataValidationSchema),
  });

  const registerWithMask = useHookFormMask(register);

  const isTedTransferToDockBank =
    isTEDTransfer && watch("bank.bankCode") === DOCK_BANK_CODE.toString();

  const handleAccountExists = ({
    account,
    contact,
  }: {
    contact: Contact;
    account: ContactAccount;
  }) => {
    searchParams.set(URL_PARAMS.beneficiary, contact.Id);
    searchParams.set(URL_PARAMS.accountId, account.Id);
    // searchParams.set(URL_PARAMS.type, transferType);
    searchParams.set(
      URL_PARAMS.type,
      isTedTransferToDockBank ? "p2p" : transferType,
    );
    setSearchParams(searchParams);
  };

  const handleNewAccount = async ({
    contact,
    formData,
  }: {
    contact: Contact;
    formData: TransferDataValidationSchema;
  }) => {
    const { accountDigit, accountNumber } = getAccountNumber(formData.account);

    const { ContactAccounts, ...rest } = contact;

    const transferType = searchParams.get("type") as TransferType;

    const response = await updateContact.mutateAsync({
      isFavoredContact: formData.favorite,
      contactName: rest.ContactName,
      document: rest.Document,
      accounts: [
        ...ContactAccounts,
        {
          TransferType: transferType,
          AccountDigit: accountDigit,
          AccountNumber: accountNumber,
          BankAccountType: formData.accountType,
          BankCode: formData.bank.bankCode,
          BankName: formData.bank.bankName,
          Branch: formData.branch,
          BranchDigit: "0",
        },
      ],
    });

    const account = findSameAccount({
      contact: response,
      accountInfo: formData,
    });

    searchParams.set(URL_PARAMS.beneficiary, response.Id);
    searchParams.set(URL_PARAMS.type, transferType);
    if (account) searchParams.set(URL_PARAMS.accountId, account.Id);
  };

  const handlePixByAccount = (formData: TransferDataValidationSchema) => {
    const { accountDigit, accountNumber } = getAccountNumber(formData.account);

    updateTransferAccount({
      transferType: "pix",
      isFavorite: formData.favorite,
      accountDigit,
      accountNumber,
      bankAccountType: formData.accountType,
      bankName: formData.bank.bankName,
      ISPB: Number(formData.bank.ispb),
      beneficiaryName: formData.name,
      branch: formData.branch,
      pixKey: "",
      document: formatDocument(formData.document),
      pixType: "account",
    });

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const handleNewContact = async (formData: TransferDataValidationSchema) => {
    const { accountDigit, accountNumber } = getAccountNumber(formData.account);

    if (transferType === "p2p")
      updateTransferAccount({
        transferType: "p2p",
        isFavorite: formData.favorite,
        beneficiaryName: formData.name,
        bankName: getVerticalBankName(),
        bankCode: DOCK_BANK_CODE,
        branch: formData.branch,
        accountNumber,
        accountDigit,
      });

    if (transferType === "ted")
      updateTransferAccount({
        transferType: "ted",
        accountDigit,
        accountNumber,
        bankAccountType: formData.accountType,
        bankCode: Number(formData.bank.bankCode),
        bankName: formData.bank.bankName,
        isFavorite: formData.favorite,
        branch: formData.branch,
        beneficiaryName: formData.name,
        beneficiaryDocument: formData.document,
      });
  };

  const onSubmit = async (formData: TransferDataValidationSchema) => {
    if (!isP2PTransfer && !formData.bank.bankCode) {
      setError("bank", { message: "Campo obrigatório" });
      return;
    }

    if (isTedTransferToDockBank || isP2PTransfer) {
      formData.bank = dockBankData;
      searchParams.set(URL_PARAMS.type, "p2p");
      setSearchParams(searchParams);
    }

    if (transferType === "pix") return handlePixByAccount(formData);

    // Verifica se já existe os dados de contato e conta na lista
    const exists = contactExists({
      contactList: [...data.p2p, ...data.pix, ...data.ted],
      newContact: formData,
    });

    // Caso exista, inclui as informações de parâmetros para a navegação
    if (
      exists.type === "ACCOUNT_EXISTS" &&
      exists.account.TransferType !== "pix"
    ) {
      const { account, contact } = exists;

      handleAccountExists({ account, contact });
    } else if (
      // Caso já exista o contato mas não a conta (e tenha permissão), é adicionado essa novo contato através da api
      exists.type === "NEW_ACCOUNT" &&
      hasPermission(canEditContacts)
    ) {
      const { contact } = exists;

      await handleNewAccount({ contact, formData });
    } else {
      // Caso contrário, é somente feito o fluxo de nova conta e novo contato

      handleNewContact(formData);
      searchParams.set(URL_PARAMS.type, transferType);
    }

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  useHandleSearchDependencies([URL_PARAMS.type]);

  const getDocumentMask = () => {
    const document = watch("document") as string;

    return document.length <= CPF_DIGITS
      ? "999.999.999-999"
      : "99.999.999/9999-99";
  };

  return (
    <TransferLayout.Root>
      <TransferLayout.Title
        to={routes.transfers}
        className="mb-[16px] mobile:!hidden"
      >
        Dados para transferência
      </TransferLayout.Title>
      <ScrollArea className="h-full w-full px-5">
        <form
          className="flex flex-col gap-6 px-1 pb-6"
          id="transferData"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <TextField.Label htmlFor="taxId">CPF/CNPJ</TextField.Label>
            <TextField.Root>
              <TextField.Input
                id="taxId"
                disabled={!!document}
                onPaste={(e) =>
                  setValue("document", e.clipboardData.getData("text"))
                }
                {...registerWithMask(
                  "document",
                  getDocumentMask(),
                  MASKED_INPUT_DEFAULT_PROPS,
                )}
                status={handleInputErrorStatus(!!errors.document?.message)}
              />
            </TextField.Root>
            <TextField.Helper>
              {errors.document?.message.toString()}
            </TextField.Helper>
          </div>
          <div>
            <TextField.Label htmlFor="name">Nome completo</TextField.Label>
            <TextField.Root>
              <TextField.Input
                status={handleInputErrorStatus(!!errors.name?.message)}
                id="name"
                {...register("name")}
              />
            </TextField.Root>
            <TextField.Helper>{errors.name?.message}</TextField.Helper>
          </div>
          <div className="flex items-center gap-2">
            <Switch
              id="favorite"
              onCheckedChange={(checked) => setValue("favorite", checked)}
            />
            <label
              htmlFor="favorite"
              className="font-base text-base text-neutral-darkest"
            >
              Favoritar contato
            </label>
          </div>
          <h5 className="font-base text-sm/8 font-bold text-neutral-darkest mobile:!text-base">
            Dados da conta
          </h5>
          <div>
            <SelectBankComboBox
              onBankSelect={(bank) => {
                setValue("bank", bank);
                clearErrors("bank");
              }}
              error={!!errors.bank}
              {...(isP2PTransfer && {
                bank: dockBankData,
                disabled: true,
              })}
            />
          </div>
          {!isP2PTransfer && (
            <RadioGroup.Root
              defaultValue="CC"
              className="flex-row justify-between"
              onValueChange={(
                value: TransferDataValidationSchema["accountType"],
              ) => setValue("accountType", value)}
            >
              <RadioGroup.Items className="!pl-0">
                <RadioGroup.Item
                  value="CC"
                  id="CC"
                  className="radio-button-transfers-wildcard"
                />
                <RadioGroup.Label htmlFor="CC">Corrente</RadioGroup.Label>
              </RadioGroup.Items>
              <RadioGroup.Items>
                <RadioGroup.Item
                  value="SA"
                  id="SA"
                  className="radio-button-transfers-wildcard"
                />
                <RadioGroup.Label htmlFor="SA">Poupança</RadioGroup.Label>
              </RadioGroup.Items>
              <RadioGroup.Items className="mobile:!pl-0">
                <RadioGroup.Item
                  value="PA"
                  id="PA"
                  className="radio-button-transfers-wildcard"
                />
                <RadioGroup.Label htmlFor="PA">Pagamento</RadioGroup.Label>
              </RadioGroup.Items>
            </RadioGroup.Root>
          )}
          <div className="flex gap-4">
            <div className="w-[25%]">
              <TextField.Label htmlFor="bankBranch">Agência</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="bankBranch"
                  placeholder="Digite"
                  status={handleInputErrorStatus(!!errors.branch?.message)}
                  {...registerWithMask(
                    "branch",
                    "9999",
                    MASKED_INPUT_DEFAULT_PROPS,
                  )}
                />
              </TextField.Root>
              <TextField.Helper>{errors.branch?.message}</TextField.Helper>
            </div>
            <div className="w-[75%]">
              <TextField.Label htmlFor="account">Conta</TextField.Label>
              <TextField.Root>
                <TextField.Input
                  id="account"
                  status={handleInputErrorStatus(!!errors.account?.message)}
                  placeholder="Digite"
                  onChange={({ target }) => {
                    const formattedValue = formatAccount(target.value);
                    setValue("account", formattedValue);
                  }}
                  value={watch("account")}
                />
              </TextField.Root>
              <TextField.Helper>{errors.account?.message}</TextField.Helper>
            </div>
          </div>
        </form>
      </ScrollArea>
      <TransferLayout.Footer>
        <Button.Root
          fullWidth
          form="transferData"
          isLoading={updateContact.isPending}
        >
          Continuar
        </Button.Root>
      </TransferLayout.Footer>
    </TransferLayout.Root>
  );
};
