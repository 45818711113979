/* eslint-disable @typescript-eslint/ban-ts-comment */
import { queryClient } from "@/lib";
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Provider } from "@hyperlocal/vital";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createPortal } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { getVertical } from "./utils";

function App() {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");
  const toastPosition = isMobile ? "bottom-center" : "top-right";

  return (
    <Provider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === "development" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
          <Routes />

          {createPortal(<Toaster position={toastPosition} />, document.body)}
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
