import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useGetFavoredData } from "@/hooks";
import { useSuspenseGetContacts } from "@/services/contacts";
import { TransferType } from "@/types";
import { DOCK_BANK_CODE, getVerticalBankName, URL_PARAMS } from "@/utils";
import { useSearchParams } from "react-router-dom";
import {
  TransferAccount,
  TransferAccountContext,
  TransformContact,
} from "./types";

const Context = createContext<TransferAccountContext>({
  transferAccount: null,
  updateTransferAccount: () => null,
  transformContact: () => null,
});

const transformContact = ({
  contact,
  contactAccount,
}: TransformContact): TransferAccount => {
  if (contactAccount?.TransferType === "p2p")
    return {
      transferType: "p2p",
      isFavorite: contact.IsFavoredContact,
      beneficiaryName: contact.ContactName,
      branch: contactAccount.Branch,
      accountNumber: contactAccount.AccountNumber,
      accountDigit: contactAccount.AccountDigit,
      bankCode: DOCK_BANK_CODE,
      bankName: getVerticalBankName(),
    };

  if (contactAccount?.TransferType === "ted")
    return {
      transferType: "ted",
      beneficiaryName: contact.ContactName,
      beneficiaryDocument: contact.Document,
      bankAccountType: contactAccount.BankAccountType,
      isFavorite: contact.IsFavoredContact,
      bankCode: Number(contactAccount.BankCode),
      bankName: contactAccount.BankName,
      accountNumber: contactAccount.AccountNumber,
      accountDigit: contactAccount.AccountDigit,
      branch: contactAccount.Branch,
    };
};

const useGetInitialState = (): TransferAccount => {
  const [searchParams] = useSearchParams();

  const accountId = searchParams.get(URL_PARAMS.accountId);
  const favoredData = useGetFavoredData();

  if (!accountId || !favoredData) return null;

  const transferData = favoredData.ContactAccounts.find(
    (account) => account.Id === accountId,
  );

  return transformContact({
    contact: favoredData,
    contactAccount: transferData,
  });
};

export const TransferAccountProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const initialState = useGetInitialState();
  const { data } = useSuspenseGetContacts();
  const [transferAccount, setTransferAccount] =
    useState<TransferAccount>(initialState);

  const transferType = searchParams.get(URL_PARAMS.type) as TransferType;

  const accountId = searchParams.get(URL_PARAMS.accountId);

  const beneficiary = searchParams.get(URL_PARAMS.beneficiary);

  const updateTransferData = useCallback((data: TransferAccount) => {
    setTransferAccount(data);
  }, []);

  const providerValue = useMemo(
    () => ({
      transferAccount,
      updateTransferAccount: updateTransferData,
      transformContact,
    }),
    [transferAccount, updateTransferData],
  );

  useEffect(() => {
    const isAccountFromList =
      !!transferType && transferType !== "pix" && !!accountId && beneficiary;

    if (isAccountFromList) {
      const contact = data[transferType].find(
        (contact) => contact.Id === beneficiary,
      );

      const account = contact?.ContactAccounts.find(
        (account) => account.Id === accountId,
      );

      const transferData = transformContact({
        contact,
        contactAccount: account,
      });

      if (transferData) updateTransferData(transferData);
    }
  }, [accountId, beneficiary, data, transferType, updateTransferData]);

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export const useTransferAccount = () => {
  const value = useContext(Context);

  if (!value)
    throw new Error(
      "TransferAccountContext must be used within TransferAccountProvider",
    );

  return value;
};
