import { useState } from "react";
import { useTransferAccount } from "@/features/transfers/providers";
import { useMediaQuery } from "@/hooks";
import { queryClient } from "@/lib";
import { pixQueryKey, useGetPix } from "@/services/pix";
import { PixDetailResponse } from "@/services/pix/types";
import { routes, URL_PARAMS } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  Sheet,
  Switch,
  TextField,
} from "@hyperlocal/vital2";
import { useNavigate, useSearchParams } from "react-router-dom";

const PixKeyData = ({ data }: { data: PixDetailResponse }) => {
  const { updateTransferAccount } = useTransferAccount();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isFavoriteParam = searchParams.get(URL_PARAMS.isFavorite);

  const onHandleContinue = () => {
    updateTransferAccount({
      transferType: "pix",
      pixKey: data.key,
      bankName: data.bankName,
      branch: "",
      beneficiaryName: data.name,
      accountDigit: "",
      accountNumber: "",
      document: data.documentMask,
      isFavorite: JSON.parse(isFavoriteParam) || false,
      bankAccountType: data.bankAccountType,
      pixType: "pixKey",
      ISPB: data.ispb,
    });

    queryClient.removeQueries({ queryKey: ["getPixDetail", data.key] });

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const handleOtherInstitution = () => {
    navigate({
      pathname: routes.transfersData,
      search: searchParams.toString(),
    });
  };

  return (
    <div className="mt-2 flex flex-col gap-4">
      <button className="w-full" onClick={onHandleContinue}>
        <Card className="flex w-full cursor-pointer items-center gap-2">
          <Avatar.Root size="md" variant="secondary">
            <Avatar.Slot name="GeralBank" />
          </Avatar.Root>
          <div className="flex flex-col text-left">
            <span className="text-base text-neutral-darkest">
              {data.bankName}
            </span>
            <span className="text- text-neutral-darkest">{data.name}</span>
          </div>
          <Icon
            name="ArrowArrowNoLineRight"
            className="ml-auto size-6 flex-shrink-0 fill-neutral-darkest"
          />
        </Card>
      </button>
      <Button.Root variant="secondary" onClick={handleOtherInstitution}>
        Outra instituição
      </Button.Root>
    </div>
  );
};

interface InsertPixKeyProps {
  isOpen: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
}

type DialogState = "insertKey" | "keyData";

export const InsertPixKey = ({ isOpen, onOpenChange }: InsertPixKeyProps) => {
  const [pixKey, setPixKey] = useState("");
  const [dialogState, setDialogState] = useState<DialogState>("insertKey");
  const [isFavoriteContact, setIsFavoriteContact] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery("mobile");

  const { isLoading, refetch, data } = useGetPix({
    enabled: false,
    pixKey: pixKey.trim(),
  });

  const isFetching = isLoading;

  const onContinue = async () => {
    refetch().then(({ isError }) => {
      if (isError) return;
      setDialogState("keyData");
    });
  };

  const isKeyData = dialogState === "keyData";

  const handleClose = () => {
    queryClient.removeQueries({ queryKey: pixQueryKey.byFetch(pixKey) });
    setDialogState("insertKey");
    setPixKey("");
    onOpenChange((prev) => !prev);
  };

  const handleFavorite = (checked: boolean) => {
    searchParams.set(URL_PARAMS.isFavorite, JSON.stringify(checked));
    setSearchParams(searchParams);
    setIsFavoriteContact(checked);
  };

  return (
    <>
      {!isMobile && (
        <div>
          <Dialog.Root open={isOpen} onOpenChange={handleClose}>
            <Dialog.Content className="gap-0">
              <Dialog.Header>
                <Dialog.Title>Chave Pix</Dialog.Title>
              </Dialog.Header>

              {isKeyData && !!data ? (
                <PixKeyData data={data} />
              ) : (
                <>
                  <div className="mt-2">
                    <TextField.Label htmlFor="pixKey">
                      Informe a chave Pix
                    </TextField.Label>
                    <TextField.Root>
                      <TextField.Input
                        id="pixKey"
                        placeholder="CPF, CNPJ, Telefone, E-mail, Chave aleatória"
                        value={pixKey}
                        onChange={({ target }) => setPixKey(target.value)}
                      />
                    </TextField.Root>
                    <div className="mt-4 flex w-fit gap-3">
                      <Switch
                        id="favoriteContact"
                        checked={isFavoriteContact}
                        onCheckedChange={handleFavorite}
                        disabled={isFetching}
                      />
                      <label
                        htmlFor="favoriteContact"
                        className="cursor-pointer select-none"
                      >
                        Favoritar contato
                      </label>
                    </div>
                  </div>
                  <Dialog.Footer className="mt-6">
                    <Dialog.Close asChild>
                      <Button.Root
                        fullWidth
                        variant="secondary"
                        disabled={isFetching}
                      >
                        Cancelar
                      </Button.Root>
                    </Dialog.Close>
                    <Button.Root
                      fullWidth
                      disabled={!pixKey}
                      onClick={onContinue}
                      isLoading={isLoading}
                    >
                      Continuar
                    </Button.Root>
                  </Dialog.Footer>
                </>
              )}
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div>
          <Sheet.Root open={isOpen} onOpenChange={onOpenChange}>
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Chave Pix</Sheet.Title>
              </Sheet.Header>
              {isKeyData && !!data ? (
                <PixKeyData data={data} />
              ) : (
                <>
                  <div>
                    <TextField.Label htmlFor="pixKey">
                      Informe a chave Pix
                    </TextField.Label>
                    <TextField.Root>
                      <TextField.Input
                        id="pixKey"
                        placeholder="CPF, CNPJ, Telefone, E-mail, Chave aleatória"
                        value={pixKey}
                        onChange={({ target }) => setPixKey(target.value)}
                      />
                    </TextField.Root>
                    <div className="mt-4 flex w-fit gap-3">
                      <Switch
                        id="favoriteContact"
                        checked={isFavoriteContact}
                        onCheckedChange={handleFavorite}
                        disabled={isFetching}
                      />
                      <label
                        htmlFor="favoriteContact"
                        className="cursor-pointer select-none"
                      >
                        Favoritar contato
                      </label>
                    </div>
                  </div>
                  <Sheet.Footer className="flex-row gap-2">
                    <Sheet.Close asChild>
                      <Button.Root
                        fullWidth
                        variant="secondary"
                        disabled={isFetching}
                      >
                        Cancelar
                      </Button.Root>
                    </Sheet.Close>
                    <Button.Root
                      fullWidth
                      disabled={!pixKey}
                      onClick={onContinue}
                      isLoading={isLoading}
                    >
                      Continuar
                    </Button.Root>
                  </Sheet.Footer>
                </>
              )}
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};
